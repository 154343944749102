import "normalize.css";
import "source-sans-pro/source-sans-pro.css";
import React from "react";
import classNames from "classnames";
import { isDesktop } from "@janitza/websdk-lib-desktop";
import { Content, Footer, Header, ModalArea, NotificationArea } from "../layout";
import styles from "./Container.module.scss";
import { AlarmsProvider, FeatureTogglesProvider, ModalsProvider, NotificationsProvider, ProductProvider, ProjectProvider, UserProvider, } from "../../contexts";
export default function Container({ children, isHeaderHidden = isDesktop(), isFooterHidden = isDesktop(), className, style, }) {
    return (React.createElement(NotificationsProvider, null,
        React.createElement(ModalsProvider, null,
            React.createElement(FeatureTogglesProvider, null,
                React.createElement(ProductProvider, null,
                    React.createElement(ProjectProvider, null,
                        React.createElement(UserProvider, null,
                            React.createElement(AlarmsProvider, null,
                                React.createElement("div", { className: classNames(styles.container, className), style: style },
                                    !isHeaderHidden && React.createElement(Header, { className: styles.header }),
                                    React.createElement(ModalArea, null),
                                    React.createElement(NotificationArea, null),
                                    React.createElement(Content, { isHeaderHidden: isHeaderHidden, isFooterHidden: isFooterHidden, className: styles.content }, children),
                                    !isFooterHidden && React.createElement(Footer, { className: styles.footer }))))))))));
}
