import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import styles from "./NotificationArea.module.scss";
import Notification from "../../Notification/Notification";
import { NotificationsContext } from "../../../contexts";
const NOTIFICATIONS_REFRESH_INTERVAL_MS = 100;
const IS_NOTIFICATION_TIMEOUT_SHOWN = false;
export default function NotificationArea({ className, style }) {
    const { locale } = useIntl();
    const { notifications, removeById, pauseTimeoutForNotification, continueTimeoutForNotification, refreshNotifications, } = useContext(NotificationsContext);
    useEffect(() => {
        if (notifications.length > 0) {
            const interval = setInterval(() => refreshNotifications(), NOTIFICATIONS_REFRESH_INTERVAL_MS);
            return () => clearInterval(interval);
        }
        return undefined;
    }, [notifications, refreshNotifications]);
    return (React.createElement("div", { className: classNames(styles.notificationArea, className), style: style }, notifications.map((entry) => {
        const { timeout } = entry;
        const displaySeconds = timeout
            ? (timeout.remaining() / 1000).toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
            : undefined;
        return (React.createElement(Notification, { key: entry.id, type: entry.type, title: entry.title, content: entry.content, footer: IS_NOTIFICATION_TIMEOUT_SHOWN && displaySeconds ? `( ${displaySeconds}s )` : undefined, onClose: entry.isClosable ? () => removeById(entry.id) : undefined, onMouseOver: () => pauseTimeoutForNotification(entry.id), onMouseOut: () => continueTimeoutForNotification(entry.id) }));
    })));
}
