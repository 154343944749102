import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { isDesktop } from "@janitza/websdk-lib-desktop";
import styles from "./ModalArea.module.scss";
import { ModalsContext, ProductContext, UserContext } from "../../../contexts";
import { Modal } from "./components";
import { REDIRECT_AFTER_LOGIN_SEARCHPARAM, REDIRECT_DELAY_AFTER_LOGIN_OR_LOGOUT_MS } from "../../../config";
import { LoginModalWindow, ReleaseNotesModalWindow } from "../../modalWindows";
import { doShowReleaseNotes, markVersionAsRead } from "../../../helpers";
const ADDITIONAL_DELAY_AFTER_REDIRECT_MS = 500;
export default function ModalArea({ className, style }) {
    const { modals, push: pushModal, pop: popModal, setPortalRoot } = useContext(ModalsContext);
    const { isLoggedIn } = useContext(UserContext);
    const { sanitizedVersion } = useContext(ProductContext);
    const lastEntry = useMemo(() => modals.at(-1), [modals]);
    const [isUrlParamsHandled, setIsUrlParamsHandled] = useState(false);
    useEffect(() => {
        if (!isDesktop() && isLoggedIn && sanitizedVersion) {
            doShowReleaseNotes(sanitizedVersion)
                .then((result) => {
                if (result) {
                    setTimeout(() => {
                        pushModal({
                            content: React.createElement(ReleaseNotesModalWindow, { onClose: popModal }),
                            doesCoverContainer: true,
                        });
                        markVersionAsRead(sanitizedVersion).catch(console.error);
                    }, REDIRECT_DELAY_AFTER_LOGIN_OR_LOGOUT_MS + ADDITIONAL_DELAY_AFTER_REDIRECT_MS);
                }
            })
                .catch(console.error);
        }
    }, [isLoggedIn, popModal, pushModal, sanitizedVersion]);
    const handleRedirectUrlParam = useCallback((target) => {
        if (target) {
            const redirectToTarget = () => {
                window.location.href = target;
            };
            if (!isLoggedIn) {
                pushModal({
                    content: React.createElement(LoginModalWindow, { onSuccess: () => redirectToTarget(), onClose: popModal }),
                });
            }
            else {
                redirectToTarget();
            }
        }
    }, [isLoggedIn, popModal, pushModal]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!isUrlParamsHandled) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const target = urlSearchParams.get(REDIRECT_AFTER_LOGIN_SEARCHPARAM) || undefined;
                setIsUrlParamsHandled(true);
                if (target)
                    handleRedirectUrlParam(target);
            }
        }, 250);
        return () => clearTimeout(timeout);
    }, [handleRedirectUrlParam, isLoggedIn, isUrlParamsHandled]);
    return (React.createElement("div", { ref: setPortalRoot, className: classNames(styles.modalArea, className), style: style }, lastEntry && (React.createElement(Modal, { key: lastEntry.id, isSimplyClosable: lastEntry.isSimplyClosable, doesCoverContainer: lastEntry.doesCoverContainer, onClose: lastEntry.onClose }, lastEntry.content))));
}
