import React, { useContext } from "react";
import classNames from "classnames";
import styles from "./Header.module.scss";
import { LanguageSelector, NavBar, Logo, UserPanel } from "./components";
import { IfUserIsLoggedIn, IfUserIsNotLoggedIn, UserContext } from "../../../contexts";
export default function Header({ className, style }) {
    const { isLoggedIn } = useContext(UserContext);
    return (React.createElement("div", { className: classNames(styles.header, isLoggedIn && styles.isLoggedIn, className), style: style },
        React.createElement("div", { className: classNames(styles.section, styles.left) },
            React.createElement(IfUserIsLoggedIn, null,
                React.createElement(NavBar, { className: classNames(styles.element, styles.navbar) })),
            React.createElement(IfUserIsNotLoggedIn, null,
                React.createElement(LanguageSelector, { className: classNames(styles.element, styles.languageSelector) }))),
        React.createElement("div", { className: classNames(styles.section, styles.right) },
            React.createElement(Logo, { className: classNames(styles.element, styles.logo) }),
            React.createElement(UserPanel, { className: classNames(styles.element, styles.userpanel) }))));
}
