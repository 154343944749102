import { useIntl } from "react-intl";
import { useContext, useMemo } from "react";
import i18n from "./useMenuEntries.i18n";
import { EProductEdition, EProductEditionPremo, EUserType } from "../../../../../enums";
import { FeatureTogglesContext, ProductContext, ProjectContext, UserContext } from "../../../../../contexts";
import { alarmListSvg, changelogSvg, chartLineLightSvg, dashboardSvg, deviceListSvg, deviceSvg, emailSvg, eventBrowserSvg, fileChartPieLightSvg, graduationCapSolidWhiteSvg, kpiSvg, licenceSvg, managersSvg, onlineRecorderSvg, opcUaClientSvg, picmanSvg, profileSvg, questionSvg, sankeySvg, settingsSvg, systeminfoSvg, systemSvg, timeManagementSvg, usersSvg, verwaltungSvg, logicSvg, filmSvg, landingpageSvg, } from "./icons";
// eslint-disable-next-line complexity
export default function useMenuEntries() {
    const { formatMessage, locale } = useIntl();
    const energyPrefix = `/energy/${locale}`;
    const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
    const { isEditionAtLeast, isEditionPremoAtLeast, isPremoEnabled } = useContext(ProductContext);
    const { hasPermission, isUserType } = useContext(UserContext);
    const { isMainProjectSet } = useContext(ProjectContext);
    // Licensing
    const isAtLeastService = isEditionAtLeast(EProductEdition.SERVICE);
    const isAtLeastUltimate = isEditionAtLeast(EProductEdition.ULTIMATE);
    const isAtLeastStandard = isEditionPremoAtLeast(EProductEditionPremo.STANDARD);
    const isAtLeastExpert = isEditionPremoAtLeast(EProductEditionPremo.EXPERT);
    const isAtLeastStandardOrService = isAtLeastStandard || isAtLeastService;
    const isAtLeastStandardOrUltimate = isAtLeastStandard || isAtLeastUltimate;
    const isAtLeastExpertOrUltimate = isAtLeastExpert || isAtLeastUltimate;
    // https://docs.google.com/spreadsheets/d/1PBzpzFuvvAFql9mivb84z1PHyx6bLbjy9UR0bhjLgr4/edit#gid=0
    const entries = useMemo(
    // eslint-disable-next-line complexity
    () => [
        {
            id: "landingpage",
            name: formatMessage(i18n.landingpage),
            iconSrc: landingpageSvg,
            href: "/landingpage",
            isShown: true,
        },
        {
            id: "overview",
            name: formatMessage(i18n.overview),
            iconSrc: deviceListSvg,
            href: "/overview",
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                hasPermission("MANDANT_ENERGY:DeviceList:READ") &&
                isFeatureToggleEnabled("pasw-feature-spa-devicelist2") &&
                isFeatureToggleEnabled("pasw-feature-new-devicelist-container-navigation"),
        },
        {
            id: "devicelist",
            name: formatMessage(i18n.overview),
            iconSrc: deviceListSvg,
            href: "/devicelist",
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                hasPermission("MANDANT_ENERGY:DeviceList:READ") &&
                isFeatureToggleEnabled("pasw-feature-spa-devicelist1") &&
                isFeatureToggleEnabled("pasw-feature-new-devicelist-container-navigation"),
        },
        {
            id: "overview-old",
            name: formatMessage(i18n.overview),
            iconSrc: deviceListSvg,
            href: `${energyPrefix}/overview.html`,
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                hasPermission("MANDANT_ENERGY:DeviceList:READ") &&
                !isFeatureToggleEnabled("pasw-feature-new-devicelist-container-navigation"),
        },
        {
            id: "dashboards",
            name: formatMessage(i18n.dashboards),
            iconSrc: dashboardSvg,
            href: isPremoEnabled ? "/dashboards" : `${energyPrefix}/dashboards.html`,
            isShown: isMainProjectSet && isAtLeastStandardOrUltimate,
        },
        {
            id: "reports",
            name: formatMessage(i18n.reportManager),
            iconSrc: fileChartPieLightSvg,
            href: "/reports",
            isShown: isMainProjectSet && isAtLeastStandard && hasPermission("MANDANT_ENERGY:Reports:READ"),
        },
        {
            id: "alarms",
            name: formatMessage(i18n.alarms),
            iconSrc: alarmListSvg,
            href: `${energyPrefix}/alarm-list.html`,
            isShown: isMainProjectSet && isAtLeastStandardOrService,
        },
        {
            id: "measurementAnalysis",
            name: formatMessage(i18n.measurementAnalysis),
            iconSrc: chartLineLightSvg,
            href: "/chartboard?analysisMode=MEASUREMENT",
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                isFeatureToggleEnabled("pasw-feature-kpi-analysis-menu-entry"),
        },
        {
            id: "keyPerformanceAnalysis",
            name: formatMessage(i18n.keyPerformanceAnalysis),
            iconSrc: kpiSvg,
            href: "/chartboard?analysisMode=KPI",
            isShown: isMainProjectSet &&
                isAtLeastExpertOrUltimate &&
                isFeatureToggleEnabled("pasw-feature-kpi-analysis-menu-entry"),
        },
        {
            id: "eventBrowser",
            name: formatMessage(i18n.eventBrowser),
            iconSrc: eventBrowserSvg,
            href: "/tebrowser",
            isShown: isMainProjectSet && isAtLeastStandardOrService && hasPermission("MANDANT_ENERGY:EventBrowser:READ"),
        },
        {
            id: "configurators",
            name: formatMessage(i18n.configurators),
            iconSrc: managersSvg,
            isShown: isMainProjectSet && isAtLeastExpertOrUltimate,
            children: [
                {
                    id: "kpiManager",
                    name: formatMessage(i18n.kpiManager),
                    iconSrc: kpiSvg,
                    href: `${energyPrefix}/kpi-manager.html`,
                    isShown: isAtLeastExpertOrUltimate,
                },
                {
                    id: "sankeyManager",
                    name: formatMessage(i18n.sankeyManager),
                    iconSrc: sankeySvg,
                    href: `${energyPrefix}/sankey-manager.html`,
                    isShown: isAtLeastExpertOrUltimate,
                },
            ],
        },
        {
            id: "settings",
            name: formatMessage(i18n.settings),
            iconSrc: settingsSvg,
            isShown: true,
            children: [
                {
                    id: "userSettings",
                    name: formatMessage(i18n.userSettings),
                    iconSrc: profileSvg,
                    href: `${energyPrefix}/profile.html`,
                    isShown: true,
                },
                {
                    id: "system",
                    name: formatMessage(i18n.system),
                    iconSrc: systemSvg,
                    href: `${energyPrefix}/server-config.html`,
                    isShown: isUserType(EUserType.ADMIN) ||
                        ["SERVER:projects", "config:@SERVER:projects", "SERVER:SET_SERVERID"].every((p) => hasPermission(p)),
                },
                {
                    id: "email",
                    name: formatMessage(i18n.email),
                    iconSrc: emailSvg,
                    href: `${energyPrefix}/email.html`,
                    isShown: isUserType(EUserType.ADMIN) || hasPermission("SERVER:EMAIL"),
                },
            ],
        },
        {
            id: "administration",
            name: formatMessage(i18n.administration),
            iconSrc: verwaltungSvg,
            isShown: isMainProjectSet &&
                isAtLeastStandardOrService &&
                [
                    "UserEditor:READ",
                    "ENERGY:DeviceOnlineRecordings:READ",
                    "PROJECTS:ANY_PROJECT:TIMEMANAGEMENT:READ",
                    "ENERGY:OPCUACONFIG:READ",
                    "ENERGY:Asset:READ",
                ].some((p) => hasPermission(p)),
            children: [
                {
                    id: "deviceManager",
                    name: formatMessage(i18n.deviceManager),
                    iconSrc: deviceSvg,
                    href: "/devicemanager",
                    isShown: isAtLeastStandard && isFeatureToggleEnabled("pasw-feature-device-manager-menu-entry"),
                },
                {
                    id: "logicEditor",
                    name: formatMessage(i18n.logicEditor),
                    iconSrc: logicSvg,
                    href: "/logiceditor",
                    isShown: isAtLeastStandard && isFeatureToggleEnabled("pasw-feature-logic-editor-menu-entry"),
                },
                {
                    id: "userManagement",
                    name: formatMessage(i18n.userManagement),
                    iconSrc: usersSvg,
                    href: `${energyPrefix}/security-edit.html`,
                    isShown: isAtLeastStandardOrUltimate && hasPermission("UserEditor:READ"),
                },
                {
                    id: "onlineRecorder",
                    name: formatMessage(i18n.onlineRecorder),
                    iconSrc: onlineRecorderSvg,
                    href: `${energyPrefix}/online-recorder.html`,
                    isShown: isAtLeastStandardOrService && hasPermission("ENERGY:DeviceOnlineRecordings:READ"),
                },
                {
                    id: "timeManagement",
                    name: formatMessage(i18n.timeManagement),
                    iconSrc: timeManagementSvg,
                    href: `${energyPrefix}/time-management.html`,
                    isShown: isAtLeastStandardOrService && hasPermission("PROJECTS:ANY_PROJECT:TIMEMANAGEMENT:READ"),
                },
                {
                    id: "opcuaClient",
                    name: formatMessage(i18n.opcuaClient),
                    iconSrc: opcUaClientSvg,
                    href: "/opcua",
                    isShown: isAtLeastExpertOrUltimate && hasPermission("ENERGY:OPCUACONFIG:READ"),
                },
                {
                    id: "pictureManager",
                    name: formatMessage(i18n.pictureManager),
                    iconSrc: picmanSvg,
                    href: `${energyPrefix}/asset-manager2.html`,
                    isShown: isAtLeastStandardOrUltimate && hasPermission("ENERGY:Asset:READ"),
                },
                {
                    id: "masterData",
                    name: formatMessage(i18n.masterData),
                    iconSrc: settingsSvg,
                    href: "/settings/#/master-data",
                    isShown: isFeatureToggleEnabled("pasw-feature-settings-spa-in-menu"),
                },
            ],
        },
        {
            id: "help",
            name: formatMessage(i18n.help),
            iconSrc: questionSvg,
            isShown: true,
            children: [
                {
                    id: "help",
                    name: formatMessage(i18n.help),
                    iconSrc: questionSvg,
                    href: `/help/${locale}/index.html`,
                    target: "_modal",
                    isShown: true,
                },
                {
                    id: "janipedia",
                    name: formatMessage(i18n.janipedia),
                    iconSrc: graduationCapSolidWhiteSvg,
                    href: "https://wiki.janitza.de/",
                    target: "_blank",
                    isShown: true,
                },
                {
                    id: "tutorials",
                    name: formatMessage(i18n.tutorials),
                    iconSrc: filmSvg,
                    href: "https://youtube.com/@gridvis",
                    target: "_blank",
                    isShown: true,
                },
            ],
        },
        {
            id: "releaseNotes",
            name: formatMessage(i18n.releaseNotes),
            iconSrc: changelogSvg,
            href: `/help/${locale}/releasenotes.html`,
            target: "_modal",
            isShown: true,
        },
        {
            id: "license",
            name: formatMessage(i18n.license),
            iconSrc: licenceSvg,
            href: `${energyPrefix}/license.html`,
            isShown: hasPermission("SERVER:license"),
        },
        {
            id: "systemInfo",
            name: formatMessage(i18n.systemInfo),
            iconSrc: systeminfoSvg,
            href: `${energyPrefix}/system-info.html`,
            isShown: true,
        },
    ], [
        energyPrefix,
        formatMessage,
        hasPermission,
        isAtLeastStandard,
        isAtLeastExpertOrUltimate,
        isAtLeastStandardOrService,
        isAtLeastStandardOrUltimate,
        isFeatureToggleEnabled,
        isUserType,
        locale,
        isMainProjectSet,
        isPremoEnabled,
    ]);
    return [entries];
}
