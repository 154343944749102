import { projects as projectsApi, user as userApi } from "../../../../helpers/apis";
export default function getContextData() {
    return userApi()
        .then((data) => {
        if (!data)
            return undefined;
        return data.mainProjectName;
    })
        .then((mainProjectName) => {
        return projectsApi().then((data) => {
            if (!data)
                return undefined;
            const projects = data.project.map((it) => ({
                id: it.id,
                name: it.name,
                path: it.path,
                isRequiringDatabaseMigration: it.isRequiringDatabaseMigration,
                isMainProject: it.name === mainProjectName,
            }));
            return { projects };
        });
    });
}
